<template>
    <div>
        <h1>Выберите период</h1>
        <b-field>
            <b-datepicker
                placeholder="Начало"
                @input="formatDate($event, 'start')"
                >
            </b-datepicker>
            <b-datepicker
                placeholder="Конец"
                @input="formatDate($event, 'end')"
                >
            </b-datepicker>
            <button 
                type="button" 
                class="button is-primary"
                @click="getMailgannerStats"
                >
                <span>Показать</span>
            </button>
        </b-field>

        <div class="stats-block">
            <b-loading :is-full-page="false" :active='isLoading'></b-loading>
            <ul v-if="!isLoading" >
                <li v-for='(value, key) in stats' :key="key">{{getStatTranslation(key)}} - {{value}}</li>
            </ul>
        </div>
        
    </div>
</template>

<script>
    import axiois from 'axios'

    export default {
        name:'MailganerStat',
        data() {
            return {
                isLoading: false,
                dates: {
                    start_date: '',
                    end_date: ''
                },
                stats: {},
                statsTranslation: {
                    send_ok: 'Успешные отправки',
                    send_fail: 'Неуспешные отправки',
                    stop: 'Несуществующие имейлы',
                    open_msg: 'Писем открыто',
                    bad: 'Не является имейлом',
                    trap: 'Спам-ловушки',
                    click_link: 'Кликов в письмах',
                    total: 'Всего на отправку',
                    fbl: 'Жалобщики'
                },
                authToken: ''
            }
        },
        mounted() {
            this.authToken = localStorage.getItem('lh-admin-panel-auth-token');
           
        },
        methods: {
            formatDate(date, position) {
                switch(position) {
                    case 'start': this.dates.start_date = date.toLocaleDateString().split('.').reverse().join('-')
                    break;
                    case 'end': this.dates.end_date = date.toLocaleDateString().split('.').reverse().join('-')
                    break;
                }
            },
           async getMailgannerStats() {
                if(this.dates.start_date && this.dates.end_date) {
                    this.isLoading = true
                    let data = JSON.stringify(this.dates)
                   await axiois.post(`${this.$store.getters.admin_api_url}/tools/email_stat`, data, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type':'application/json',
                            'Auth-Token': this.authToken
                        },
                        
                    })
                    .then((resp) => {
                        this.stats = resp.data
                        this.isLoading = false
                    })

                    .catch(e => console.log('Error in requesting mailganer stats',e))
                }
            },
            getStatTranslation(stat) {
                return this.statsTranslation[stat]
            }

        }
        
    }
</script>

<style lang="scss" scoped>

.field.has-addons .control:not(:last-child) {
    margin-right: 16px;
}
.datepicker {
    width: 25%;
}

.stats-block {
    position: relative;
    margin-top: 24px;
    min-height: 50%;
    li {
        margin-bottom: 8px;
    }
}



</style>